import CreateLinkForm from "../components/CreateLinkForm";
import Dashboard from "../components/Dashboard";

const NewLink = () => {
  return (
    <Dashboard>
      <CreateLinkForm />
    </Dashboard>
  );
}
export default NewLink;
