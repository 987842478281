import { Fragment } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import NotFound from '../pages/404';
import Links from '../pages/Links';
import Login from '../pages/Login';
import NewLink from '../pages/NewLink';
import PrivateRoute from './PrivateRoute';

const CustomRoutes = () => (
  <Router>
    <Fragment>
      <Routes>
        <Route path="/login" exact element={<Login />} />
        <Route exact path='/dashboard' element={<PrivateRoute />}>
          <Route path="/dashboard/*" element={<NotFound />} />
          <Route exact path='/dashboard' element={<Links />} />
          <Route exact path='/dashboard/new-link' element={<NewLink />} />
        </Route>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<Navigate to="/dashboard" />} />
      </Routes>
    </Fragment>
  </Router>
);

export default CustomRoutes;