import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useAuth } from "../hooks/auth";
import api from "../services/api";

const CreateLinkForm = () => {
  const [shortenedUrl, setShortenedUrl] = useState('');
  const [user, setUser] = useState();
  const { getUser } = useAuth();

  const [formState, setFormState] = useState({
    long_url: '',
    start_date: '',
    end_date: '',
    permanent: false,
    c_new: true,
  });

  useEffect(() => {
    const getUserData = async () => {
      const user = await getUser();
      setUser(user);
    }
    getUserData();
  }, [getUser])

  const onChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    if (name === 'permanent') {
      setFormState((prevState) => ({ ...prevState, permanent: !formState.permanent }));
    } else {
      setFormState((prevState) => ({ ...prevState, [name]: value }));
    }
  }

  const handleCheckboxChange = () => {
    setFormState((prevState) => ({ ...prevState, permanent: !formState.permanent }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    api.post(`/api/v1/shorten/${user.attributes.sub}`, formState)
      .then(({ data }) => {
        setShortenedUrl(`${process.env.REACT_APP_API}/${data.hash}`)
        toast.success('Url encurtada com sucesso!')
      }).catch(() => {
        toast.error('Houve um erro ao encurtar a Url, tente novamente mais tarde.')
      })
  }

  return (
    <div className="bg-white rounded-lg shadow sm:mx-auto w-full sm:overflow-hidden">
      <div className="px-4 py-8 sm:px-10">
        <div className="relative mt-6">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300">
            </div>
          </div>
          <div className="relative flex justify-center text-sm leading-5">
            <span className="px-2 text-gray-500 bg-white">
              Novo Link
            </span>
          </div>
        </div>
        <div className="mt-6">
          <div className="w-full space-y-6">
            <div className="w-full">
              <div className=" relative ">
                <label className="px-4">Seu Link</label>
                <input value={formState.long_url} onChange={onChange} type="text" name="long_url" className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="https://link.com" />
              </div>
            </div>

            <div className="w-full">
              <div className=" relative flex align-center">
                <div className="my-auto w-1/3">
                  <div className="relative inline-block w-10 mr-2 align-middle select-none">
                    <input checked={formState.permanent} name="permanent" onChange={handleCheckboxChange} type="checkbox" className="checked:bg-blue-500 outline-none focus:outline-none right-4 checked:right-0 duration-200 ease-in absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer" />
                    <label className="block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer">
                    </label>
                  </div>
                  <span className="text-gray-400 font-medium">
                    Permanente
                  </span>
                </div>
                <div className="w-1/3 flex flex-col">
                  <label className="px-4">Data inicial</label>
                  <input value={formState.start_date} disabled={formState.permanent} name="start_date" onChange={onChange} type="date" className="disabled:opacity-60 rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-10/12 py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" />
                </div>
                <div className="w-1/3 flex flex-col">
                  <label className="px-4">Data final</label>
                  <input value={formState.end_date} disabled={formState.permanent} name="end_date" onChange={onChange} type="date" className="disabled:opacity-60 rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-10/12  py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" />
                </div>
              </div>
            </div>

            {shortenedUrl ?
              <div>
                <hr />
                <div className="mb-2 pt-2">
                  <label className="px-4">Sua URL:</label>
                  <div className="flex align-center">
                    <input disabled value={shortenedUrl} onChange={onChange} type="text" name="long_url" className=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="https://link.com" />
                    <button onClick={() => navigator.clipboard.writeText(shortenedUrl)} type="button" className="py-2 px-4  bg-green-600 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200 text-white w-1/4 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                      Copiar
                    </button>
                  </div>
                </div>
                <hr />
                <div className="mt-4">
                  <span className="block w-full rounded-md shadow-sm">
                    <button onClick={() => { setFormState({ permanent: false, start_date: '', end_date: '', long_url: '' }); setShortenedUrl('') }} type="button" className="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                      Criar novo
                    </button>
                  </span>
                </div>
              </div>
              :
              <div>
                <span className="block w-full rounded-md shadow-sm">
                  <button onClick={onSubmit} type="button" className="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                    Criar
                  </button>
                </span>
              </div>
            }
          </div>
        </div>
      </div>
    </div >
  );
}
export default CreateLinkForm;
