import { Link, useLocation } from 'react-router-dom';
import logo from '../assets/logo.png';

const Dashboard = ({ children }) => {
  const { pathname } = useLocation();

  return (
    <div className='w-screen flex justify-between'>
      <div className="relative bg-white dark:bg-gray-800">
        <div className="flex flex-col sm:flex-row sm:justify-around">
          <div className="w-72 h-screen">
            <div className="flex items-center justify-start mx-6 mt-10">
              <img className="h-10" src={logo} alt="Cliq.es" />
            </div>
            <nav className="mt-10 px-6 ">
              <Link className={`hover:text-gray-800 hover:bg-gray-100 flex items-center p-2 my-6 transition-colors dark:hover:text-white dark:hover:bg-gray-600 duration-200  text-gray-600 dark:text-gray-400 rounded-lg ${pathname === '/dashboard/new-link' ? 'bg-gray-100 dark:bg-gray-600' : ''} `} to="/dashboard/new-link">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span className="mx-4 text-lg font-normal">
                  Novo Link
                </span>
                <span className="flex-grow text-right">
                </span>
              </Link>
              <Link className={`hover:text-gray-800 hover:bg-gray-100 flex items-center p-2 my-6 transition-colors dark:hover:text-white dark:hover:bg-gray-600 duration-200  text-gray-800 dark:text-gray-100 rounded-lg ${pathname === '/dashboard' ? 'bg-gray-100 dark:bg-gray-600' : ''} `} to="/dashboard">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                </svg>
                <span className="mx-4 text-lg font-normal">
                  Meus Links
                </span>
                <span className="flex-grow text-right">
                </span>
              </Link>
            </nav>
          </div>
        </div>
      </div>
      {children}
    </div>
  );
}
export default Dashboard;
