import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { useAuth } from "../hooks/auth";
import api from "../services/api";

const Table = () => {
  const [hashes, setHashes] = useState([]);
  const [loading, setLoading] = useState(true);
  const { getUser } = useAuth();

  useEffect(() => {
    const getLinks = async () => {
      const user = await getUser();
      api.get(`/api/v1/hashes/${user.attributes.sub}`).then(({ data }) => {
        setHashes(data.hashes);
        setLoading(false);
      }).catch(() => {
        toast.error('Nenhum link encontrado!')
        setLoading(false);
      })
    }
    getLinks();
  }, [getUser])

  return (
    <div className="container mx-auto px-4 sm:px-8">
      <div className="py-8">
        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
          <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
            {hashes.length > 0 ?
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th scope="col" className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal">
                      Link encurtado
                    </th>
                    <th scope="col" className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal">
                      Link original
                    </th>
                    <th scope="col" className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal">
                      Validade
                    </th>
                    <th scope="col" className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal">
                      Cliques
                    </th>
                    <th scope="col" className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal">
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {hashes.map(hash => (
                    <tr key={hash.hash}>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <div className="flex items-center">
                          <div className="ml-3">
                            <p className="text-gray-900 whitespace-no-wrap">
                              {`${process.env.REACT_APP_API}/${hash.hash}`}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {hash.url}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {hash.permanent ? 'Permanente' : `De ${new Date(hash.start_date)} até  ${new Date(hash.end_date)}`}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {hash.clicks}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <Link to="#" className="text-indigo-600 hover:text-indigo-900">
                          Editar
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              :
              <div className="bg-white dark:bg-gray-800 ">
                {!loading &&
                  <div className="lg:flex lg:items-center lg:justify-between w-full mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 z-20">
                    <h2 className="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
                      <span className="block">
                        Nenhum link encontrado
                      </span>
                      <span className="block text-indigo-500">
                        Comece agora:
                      </span>
                    </h2>
                    <div className="lg:mt-0 lg:flex-shrink-0">
                      <div className=" inline-flex rounded-md shadow">
                        <Link to="/dashboard/new-link" className="py-4 px-6  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                          Criar novo
                        </Link>
                      </div>
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        </div>
      </div >
    </div >
  );
}
export default Table;
