import Dashboard from "../components/Dashboard";
import Table from "../components/Table";

const Links = () => {
  return (
    <Dashboard>
      <Table />
    </Dashboard>
  );
}
export default Links;
